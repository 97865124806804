import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { MdAddCircleOutline } from 'react-icons/md'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import axios from 'axios'
import Seo from '../components/seo'
import {
  CloseIcon,
  ContentWrapper,
  IconCont,
  ModalDesc,
  ModalSvg,
  ModalTxt,
  PrescriptionImg,
  StyledFormControl,
  StyledLabel,
  StyledModal,
  UploadBtn,
} from '../components/findForm/formElements'
import Hero from '../images/uploaded_img.svg'
import Button from '@material-ui/core/Button'
import PageNav from '../components/homeNav/PageNav'
import { BsWhatsapp } from 'react-icons/bs'
import { FaFileUpload } from 'react-icons/fa'
import BotNav from '../components/bottomNav'
import Footer from '../components/homeNav/footer/Footer'
import { Modal } from 'react-bootstrap'
import {
  StyledButton,
  StyledForm,
  StyledIcon,
  StyledInput,
} from '../components/providerSection/providerElements'
import {
  Container,
  ImgCont,
  ImgContM,
  UploadCard,
  UploadImg,
  CardWrapper,
  Select,
  SelectLabel,
} from '../style/uploadStyles'
import { AutoContainer } from '../components/location-autocomplete/autoCompleteELements'
import useAuthState from '../stores/auth'

const useStyles = makeStyles({
  formControl: {
    margin: '10px',
    minWidth: '200px',
    '@media(minWidth: 320px)': {
      width: '100%',
    },
  },
  title: {
    flexGrow: 1,
    color: '	#00b300',
    fontSize: '2em',
  },
  uploadIcon: {
    margin: '0 1em',
    '@media(minWidth: 320px)': {},
  },
  paper: {
    padding: '20px',
    margin: 'auto',
    maxWidth: 500,
  },
  textField: {
    marginLeft: '10px',
    marginRight: '10px',
    width: 200,
  },
})

export default function UploadPrescription() {
  const data = useStaticQuery(graphql`
    query ShopsQuery {
      allShops {
        edges {
          node {
            id
            name
            physicalAddress {
              county
              street
            }
            showShop
            licenseStatus
          }
        }
      }
    }
  `)

  const allStoresRaw = data.allShops.edges.map(item => {
    return item.node
  })

  const allStores = allStoresRaw.filter(
    item => item.showShop === true && item.licenseStatus,
  )

  axios.defaults.timeout = 50000

  const classes = useStyles()
  const [show, setShow] = React.useState(false)
  const [showTel, setShowTel] = useState(false)
  const [selectedPrescription, setSelectedPrescription] = React.useState({})
  const [isPrescriptionPicked, setIsPrescriptionPicked] = React.useState(false)
  const [isPrescriptionPickedPharmilymed, setIsPrescriptionPickedPharmilymed] =
    React.useState(false)
  const [prescriptionUrl, setPrescriptionUrl] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [success, setSuccess] = React.useState('')
  const [error, setError] = React.useState('')
  const [county, setCounty] = React.useState([])
  const [selectedCounty, setSelectedCounty] = React.useState([])
  const [selectedStreet, setSelectedStreet] = React.useState([])
  const [selectedShop, setSelectedShop] = React.useState([])
  const [errorTel, setErrorTel] = useState('')

  const handleShow = () => setShow(true)
  const handleChangePrescription = event => {
    setSelectedPrescription(event.target.files[0])
    setPrescriptionUrl(URL.createObjectURL(event.target.files[0]))
    setIsPrescriptionPicked(true)
  }

  const handleChangePrescriptionPharmilymed = event => {
    setSelectedPrescription(event.target.files[0])
    setPrescriptionUrl(URL.createObjectURL(event.target.files[0]))
    setIsPrescriptionPickedPharmilymed(true)
  }

  const { user, isLoggedIn, setUser } = useAuthState()

  const [uploaded, setUploaded] = React.useState(false)
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '')
  const [loadingTel, setLoadingTel] = useState(false)
  const [submittedTel, setSubmittedTel] = useState(false)

  const handleClose = () => {
    setShow(false)
    setSuccess('')
    setError('')
  }

  const handleCloseTel = () => {
    setShowTel(false)
    setErrorTel('')
  }

  React.useEffect(() => {
    if (
      (isPrescriptionPicked && !user.phoneNumber) ||
      (isPrescriptionPickedPharmilymed && !user.phoneNumber)
    ) {
      setShowTel(true)
    }
  }, [isPrescriptionPicked, isPrescriptionPickedPharmilymed])

  const uploadData = async () => {
    try {
      setErrorTel('')
      if (!phoneNumber) {
        setErrorTel('Missing required fields')
      } else {
        setLoadingTel(true)

        const data = {
          phoneNumber,
        }
        const result = await axios.patch(
          `${process.env.GATSBY_API_URL}/users/${user._id}/phoneNumber`,
          {
            ...data,
          },
        )
        setUser(result.data)
        setLoadingTel(false)
        setSubmittedTel(true)
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: EditProduct.js ~ line 54 ~ editProduct ~ error',
        err,
      )
      setLoadingTel(false)
      const { message } = err.response.data
      setErrorTel(message)
    }
  }

  const counties = [
    ...new Set(
      allStores.map(item => {
        return item.physicalAddress.county
      }),
    ),
  ]
  counties.unshift('Select an Option')

  const filteredStreetRaw = allStores.filter(function (el) {
    return el.physicalAddress.county === selectedCounty
  })
  const filteredStreet = [
    ...new Set(filteredStreetRaw.map(item => item.physicalAddress.street)),
  ]

  filteredStreet.unshift('Select an Option')

  const filteredShop = filteredStreetRaw.filter(function (el) {
    return el.physicalAddress.street === selectedStreet
  })
  filteredShop.unshift({ blank: 'Select an Option' })

  useEffect(() => {
    setCounty(counties)
  }, [])

  const uploadPrescription = async () => {
    try {
      setIsLoading(true)
      setError('')
      const formData = new FormData()

      formData.append('file', selectedPrescription)
      formData.append('patientId', user._id)
      formData.append(
        'shopId',
        filteredShop.filter(item => item.name === selectedShop)[0]?.id ||
          '638dc594986bc60016156c79',
        // '656c87fbd5e8d42264eb9435',
      )

      await axios.post(`${process.env.GATSBY_API_URL}/prescriptions`, formData)

      setIsLoading(false)
      setSuccess('uploaded')

      setSelectedPrescription({})
      setIsPrescriptionPicked(false)
      setIsPrescriptionPickedPharmilymed(false)
      setPrescriptionUrl('')
      setUploaded(true)
      //navigate('/prescription-checkout', { state: { productId } });
    } catch (err) {
      const { message } = err.response.data
      setIsLoading(false)
      setError(message)
    }
  }

  return (
    <div>
      <PageNav />
      <Container>
        <Seo
          title="Upload prescription online to a pharmacy in Kenya | Afyabook"
          description="Upload prescription to your nearest pharmacy, get quotation, buy medication and get them delivered"
          img="https://www.afyabook.com/static/upload_hero-fea6556f484fc3911d5280c94352cd4b.svg"
          keywords="online pharmacy, upload prescription, online pharmacy in kenya"
          siteUrl="https://www.afyabook.com/upload-prescription"
          canonical="https://www.afyabook.com/upload-prescription"
        ></Seo>

        <UploadCard>
          <CardWrapper>
            <h4>Upload Prescription</h4>
            <DivWrapper>
              <div>
                <ImgCont>
                  <UploadImg src="https://res.cloudinary.com/tripleaim-software/image/upload/c_scale,w_320,f_auto/v1672841216/afyabook%20images/prescription_eem19v.png" />{' '}
                </ImgCont>
                <ImgContM>
                  <UploadImg src="https://res.cloudinary.com/tripleaim-software/image/upload/c_scale,w_320,f_auto/v1672841216/afyabook%20images/prescription_eem19v.png" />{' '}
                </ImgContM>
                <div style={{ textAlign: 'center' }}>sample prescription</div>
                <a
                  href={`https://wa.me/+254792640973?text=Hello Afyabook, please quote &source=uploadPrescription`}
                  target="_blank"
                >
                  <AddCartBtn
                    css={`
                      background: #4cbb17;
                      margin: 1rem;
                      width: 90%;
                    `}
                  >
                    <BsWhatsapp style={{ display: 'inline' }} /> Share on
                    WhatsApp
                  </AddCartBtn>
                </a>
              </div>
              <div style={{ minWidth: '250px' }}>
                {!isPrescriptionPickedPharmilymed && (
                  <StyledFormControl>
                    <StyledLabel htmlFor="upload-pic">
                      {isLoggedIn() && (
                        <>
                          <input
                            style={{ display: 'none' }}
                            id="upload-pic"
                            name="upload-pic"
                            type="file"
                            onChange={e =>
                              handleChangePrescriptionPharmilymed(e)
                            }
                          />
                          <UploadBtn>
                            <FaFileUpload style={{ display: 'inline' }} /> Quick
                            Upload
                          </UploadBtn>
                        </>
                      )}
                      {!isLoggedIn() && (
                        <UploadBtn onClick={() => handleShow()}>
                          <FaFileUpload style={{ display: 'inline' }} /> Quick
                          Upload
                        </UploadBtn>
                      )}
                      {!isLoggedIn() && (
                        <StyledModal
                          show={show}
                          animation={true}
                          on={handleShow}
                        >
                          <ContentWrapper>
                            <IconCont>
                              <CloseIcon onClick={handleClose}></CloseIcon>
                            </IconCont>
                            <ModalSvg src={Hero} />
                            <ModalTxt>
                              Kindly Login to Upload your prescription
                            </ModalTxt>
                            <ModalDesc>
                              click The link below to Login <br />
                              <Link to="/Login">here</Link>
                            </ModalDesc>
                          </ContentWrapper>
                        </StyledModal>
                      )}
                    </StyledLabel>
                  </StyledFormControl>
                )}
                {isPrescriptionPickedPharmilymed && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PrescriptionImg
                      style={{ width: '50%' }}
                      src={prescriptionUrl}
                    />
                    {isLoading && <div>Loading...</div>}
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ marginTop: '1rem' }}
                      disableElevation
                      onClick={() => uploadPrescription() && handleShow()}
                      disabled={isLoading || uploaded}
                    >
                      Submit{' '}
                    </Button>
                  </div>
                )}
                <br />
                <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
                <h6 style={{ textAlign: 'center' }}>OR</h6>
                <AutoContainer>
                  <SelectLabel>Select your County</SelectLabel>
                  <Select
                    name="county"
                    id="county"
                    value={selectedCounty}
                    placeholder="select your county"
                    onChange={e => setSelectedCounty(e.target.value)}
                    style={{ borderRadius: '10px' }}
                  >
                    {county.map((item, id) => {
                      return (
                        <option key={id} value={item}>
                          {/* {item} */}
                          {item.charAt(0).toUpperCase() +
                            item.slice(1).toLowerCase()}
                        </option>
                      )
                    })}
                  </Select>

                  <SelectLabel>Select your Street or Estate</SelectLabel>
                  <Select
                    name="county"
                    placeholder="select street name"
                    id="county"
                    value={selectedStreet}
                    onChange={e => setSelectedStreet(e.target.value)}
                    style={{ borderRadius: '10px' }}
                  >
                    {filteredStreet.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item.charAt(0).toUpperCase() +
                            item.slice(1).toLowerCase()}
                          {/* {item.streetLocation || item.blank} */}
                        </option>
                      )
                    })}
                  </Select>
                  <SelectLabel>Select Pharmacy</SelectLabel>
                  <Select
                    name="shop"
                    id="county"
                    placeholder="select chemist near you"
                    value={selectedShop}
                    onChange={e => setSelectedShop(e.target.value)}
                    style={{ borderRadius: '10px' }}
                  >
                    {filteredShop.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.name
                            ? item.name.charAt(0).toUpperCase() +
                              item.name.slice(1).toLowerCase()
                            : item.blank}
                          {/* {item.name || item.blank} */}
                        </option>
                      )
                    })}
                  </Select>
                  <p>Max image size 5MB</p>
                </AutoContainer>

                {!isPrescriptionPicked && (
                  <StyledFormControl>
                    <StyledLabel htmlFor="upload-photo">
                      {isLoggedIn() && (
                        <>
                          <input
                            style={{ display: 'none' }}
                            id="upload-photo"
                            name="upload-photo"
                            type="file"
                            onChange={e => handleChangePrescription(e)}
                          />
                          <UploadBtn>
                            <MdAddCircleOutline
                              className={classes.uploadIcon}
                            />
                            Upload
                          </UploadBtn>
                        </>
                      )}
                      {!isLoggedIn() && (
                        <UploadBtn onClick={() => handleShow()}>
                          <MdAddCircleOutline className={classes.uploadIcon} />
                          Upload
                        </UploadBtn>
                      )}
                      {!isLoggedIn() && (
                        <StyledModal
                          show={show}
                          animation={true}
                          on={handleShow}
                        >
                          <ContentWrapper>
                            <IconCont>
                              <CloseIcon onClick={handleClose}></CloseIcon>
                            </IconCont>
                            <ModalSvg src={Hero} />
                            <ModalTxt>
                              Kindly Login to Upload your prescription
                            </ModalTxt>
                            <ModalDesc>
                              click The link below to Login <br />
                              <Link to="/Login">here</Link>
                            </ModalDesc>
                          </ContentWrapper>
                        </StyledModal>
                      )}
                    </StyledLabel>
                  </StyledFormControl>
                )}
                {isPrescriptionPicked && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PrescriptionImg
                      style={{ width: '50%' }}
                      src={prescriptionUrl}
                    />
                    {isLoading && <div>Loading...</div>}
                    <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
                    {selectedShop.length > 0 && (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ marginTop: '1rem' }}
                        disableElevation
                        onClick={() => uploadPrescription() && handleShow()}
                        disabled={isLoading || uploaded}
                      >
                        Submit{' '}
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </DivWrapper>
          </CardWrapper>
          {success && uploaded && (
            <StyledModal show={show} animation={true} onHide={handleShow}>
              <ContentWrapper>
                <IconCont>
                  <CloseIcon onClick={handleClose}></CloseIcon>
                </IconCont>
                <ModalSvg src={Hero} />
                <ModalTxt>Successfully Uploaded!</ModalTxt>
                <ModalDesc>
                  Thank you, you will be contacted by the pharmacy
                  representative
                </ModalDesc>
              </ContentWrapper>
            </StyledModal>
          )}
          <Modal
            show={showTel}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <StyledForm>
              <>
                <p style={{ color: 'red' }}>{errorTel}</p>
                {/* <StyledIcon onClick={handleClose} />                                         */}
                <p style={{ fontSize: '1.5em' }}>
                  Kindly provide your Phone Number so we can contact you easily.{' '}
                </p>
                <br />
                <label htmlFor="phoneNumber">Phone Number</label>
                <StyledInput
                  type="tel"
                  name="Phone Number"
                  value={phoneNumber}
                  onChange={e => {
                    setPhoneNumber(e.target.value)
                  }}
                />
                <StyledButton
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  disabled={submittedTel || loadingTel || !phoneNumber}
                  onClick={() => {
                    uploadData()
                    handleCloseTel()
                  }}
                >
                  Submit
                </StyledButton>{' '}
              </>
            </StyledForm>
          </Modal>
        </UploadCard>
      </Container>
      <BotNav />
      <Footer />
    </div>
  )
}

const AddCartBtn = styled.button`
  width: 100%;
  padding: 10px;
  background: #1d9682;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  white-space: nowrap;
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  :hover {
    transition: all 0.2s ease-in-out;
    background: #f5f5f5;
    color: #205072;
  }
`
const DivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`
